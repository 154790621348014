/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

import { Currencie } from '../../consts/consts-currencies'

export interface OrderState {
  uuid: string
  details: {
    expiredAt: number
    amount: number
    comment: string
    currencyCode: keyof typeof Currencie
    redirectUrls: {
      failUrl: string
      successUrl: string
    }
  }
  countryCode: 'RU'
}

const initialState: OrderState = {
  uuid: '',
  details: {
    expiredAt: -1,
    amount: 0,
    comment: '',
    currencyCode: 'RUB',
    redirectUrls: {
      failUrl: '',
      successUrl: '',
    },
  },
  countryCode: 'RU',
}

export const orderSlice = createSlice({
  name: 'counter',
  initialState,
  reducers: {
    setUUID: (state, action: PayloadAction<OrderState['uuid']>) => {
      state.uuid = action.payload
    },
    setDetails: (state, action: PayloadAction<OrderState['details']>) => {
      state.details = action.payload
    },
    setDetailsSuccessUrl: (state, action: PayloadAction<OrderState['details']['redirectUrls']['successUrl']>) => {
      state.details.redirectUrls.successUrl = action.payload
    },
    setCountryCode: (
      state,
      action: PayloadAction<OrderState['countryCode']>
    ) => {
      state.countryCode = action.payload
    },
    setFailUrl: (state, action: PayloadAction<OrderState['details']['redirectUrls']['failUrl']>) => {
      state.details.redirectUrls.failUrl = action.payload
    }
  },
})

// Action creators are generated for each case reducer function
export const { setUUID, setDetails, setCountryCode, setDetailsSuccessUrl, setFailUrl } = orderSlice.actions

export default orderSlice.reducer
